
import common from './common';
import debounce from './global/debounce';

const Dragdealer = common.dragdealer;

const selectors = {
	left : '.m-drag-reveal__left',
	right : '.m-drag-reveal__right',
	wrapper : '.m-drag-reveal__wrapper',
	box : '.m-box',
	image : '.m-drag-reveal__image img',
	handleClass : 'm-drag-reveal__handle'
}

function lerp(a, b, alpha) {
    return (a * (1-alpha)) + (b * alpha);
}

function inverseLerp(a, b, val) {
	return (val - a) / (b - a);
}

function minMax(min, max, val) {
	return Math.min(max,Math.max(min, val));
}

const isMedium = () => (window.innerWidth >= 800);
const isDesktop = () => (window.innerWidth >= 1000);

function createOnChangeHandler(left, right, leftBox, rightBox, leftImage, rightImage, value) {
	return function(x, y) {
		value.x = x;
		left.style.width = x*100 + '%';
		right.style.width = (1-x)*100 + '%';

		// limit 0–1 range to 0.2–0.8 of the drag space
		const movementRange = minMax(0, 1, inverseLerp(0.4, 0.6, x));

		// move between 0 and -8 given 0–1
		const transformEms = isMedium() ? -8 : -2;
		const movementLeft = lerp(0, transformEms, movementRange);
		const movementRight = lerp(0, transformEms, 1-movementRange);

		// move boxes
		leftBox.style.transform = `translate(0, ${movementLeft}em)`;
		leftBox.style.opacity = movementRange;
		rightBox.style.transform = `translate(0, ${movementRight}em)`;
		rightBox.style.opacity = 1-movementRange;

		const opacityRange = minMax(0, 1, inverseLerp(0.2, 0.8, x));
		const opacityLeft = lerp(0.6, 1, opacityRange);
		const opacityRight = lerp(0.6, 1, 1 - opacityRange);

		leftImage && (leftImage.style.opacity = opacityLeft);
		rightImage && (rightImage.style.opacity = opacityRight);
	}
}

export const initInstance = function(el) {

	const container = el;
	const dragWrapper = container.querySelector(selectors.wrapper);

	const left = container.querySelector(selectors.left);
	const right = container.querySelector(selectors.right);
	const leftBox = left.querySelector(selectors.box);
	const rightBox = right.querySelector(selectors.box);
	const leftImage = left.querySelector(selectors.image);
	const rightImage = right.querySelector(selectors.image);

	// remember last X value
	// uses object so it's passed by reference, and the changeHandler can alter it
	const value = {x : null};

	const onChangeHandler = createOnChangeHandler(left, right, leftBox, rightBox, leftImage, rightImage, value);

	const drag = new Dragdealer(dragWrapper, {
		handleClass : selectors.handleClass,
		slide : false,
		animationCallback : onChangeHandler,
	});

	window.addEventListener('resize', debounce(function() {
		value.x && onChangeHandler(value.x, 0);
	}, 200));

	const sliderInitialPos = isDesktop() ? 0.25 : 0.05;
	drag.setValue(1 - sliderInitialPos); // pos % from the right
}
